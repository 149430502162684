import { Grid, GridItem, Text, Divider } from '@chakra-ui/react'
import { _trackEventOnServerWithLogging } from '@twlvxtwlv/analytics/src/server'
import { _initDistinctId, initDistinctId } from '@twlvxtwlv/utils/src/cookie'
import { serverSideTrackEvent } from '@twlvxtwlv/analytics/src/browser'
import { useTranslation } from 'next-i18next'
import { useToast } from '@chakra-ui/react'
import { SectionHeading } from '../../../../shared/section-heading'
import { HomepageNewsletterSignupForm } from '../../../../pages/homepage/shared/homepage-newsletter-signup-form'
import { montserrat } from '../../../../../fonts'

export const TxtPlayNewsletter = () => {
  const txtPlayNewsletterId = 50
  const toast = useToast()
  const { t } = useTranslation(['common', 'txt-play'])
  const distinctId = initDistinctId()
  const onNewsletterSignupSuccess = (newsletterId: number) => {
    serverSideTrackEvent(
      { userId: distinctId },
      {
        eventName: 'homepage-txt-play-signup-success',
        newsletterId: newsletterId,
      }
    )
  }

  const onNewsletterSignupFailure = () => {
    toast({
      position: 'top-right',
      status: 'error',
      title: t('common:newsletter-signup-failed-toast-title'),
    })
  }

  return (
    <>
      <Divider />
      <Grid
        w="full"
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(1, 1fr)',
          lg: '40% 60%',
          xl: '35% 65%',
        }}
        justifyContent="center"
        gap="1rem"
        p={6}
        fontFamily={montserrat.style.fontFamily}
      >
        <GridItem margin="auto" padding={[2, 4, 4, 10]}>
          <SectionHeading title={t('txt-play:txt-play-newsletter-heading')} />
        </GridItem>
        <GridItem padding={[2, 4, 4, 10]} mx="auto" width={{ base: '100%', md: '80%' }}>
          <Text fontSize={['md', 'lg', 'lg']} mt={4} mb={12}>
            {t('txt-play:txt-play-newsletter-description')}
          </Text>
          <HomepageNewsletterSignupForm
            onSuccess={() => onNewsletterSignupSuccess(txtPlayNewsletterId)}
            onError={onNewsletterSignupFailure}
            newsletterId={txtPlayNewsletterId}
          />
        </GridItem>
      </Grid>
    </>
  )
}
