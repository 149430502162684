import { Box, Text, Grid, GridItem, Button } from '@chakra-ui/react'
import { SectionHeading } from '../../../../shared/section-heading'
import { productCard } from './product-card'
import { ProductCardFlip } from '../../../../shared/product-card-flip'
import { useState } from 'react'
import type { ProductSuite } from '@twlvxtwlv/types'
import { useTranslation } from 'next-i18next'

export const ProductDistribution = () => {
  const { t } = useTranslation('txt-play')
  const [section, setSection] = useState(productCard[0])
  const getSectionBg = (product: ProductSuite) => {
    setSection(product)
  }
  return (
    <Box
      gridTemplateColumns={['1fr']}
      w="full"
      bgColor={section.bgColor}
      bgImage={section.sectionBgImage}
      p={[4, 6, 12, 12]}
    >
      <Text
        fontSize={['14px', '14px', '18px', '18px']}
        textTransform="uppercase"
        ml={[10, 10, 14, 16]}
      >
        {t('txt-play-product-suite-title')}
      </Text>
      <SectionHeading title={t('txt-play:txt-play-product-suite-heading')} />
      {/* <Text w={{ base: '100%', sm: '100%', md: '85%', xl: '70%' }} p={4} fontSize={20}>
        {t('txt-play-product-suite-body')}
      </Text> */}
      <Grid
        w="full"
        templateColumns={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
        justifyContent="center"
        gap={[0.5]}
        alignItems={'center'}
        my={6}
      >
        {productCard.map((product) => {
          return (
            <GridItem key={product.id} alignSelf="center">
              <ProductCardFlip product={product} getSectionBg={getSectionBg} />
            </GridItem>
          )
        })}
      </Grid>
      <Grid w="full" justifyContent="center" mt={6}>
        <Button as="a" target="_blank" href="https://bit.ly/TXT_ProductSuite" variant="txtWhite">
          {t('txt-play-btn')}
        </Button>
      </Grid>
    </Box>
  )
}
