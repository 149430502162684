const artistList = [
  'Anja Schneider.png',
  'Rabea Senftenberg.png',
  'Nat Suprise.png',
  'BE_WISE.png',
  'Andreas Preis.png',
  'Moguai.png',
  'Azro.png',
  'Dj Hell.png',
  'Roger Sanchez.png',
  'Volo.png',
  'Yusuf & Yasin.png',
  'Kidd Kawaki.png',
  'And Him.png',
  'Ghozt.png',
  'Vlad Yaki.png',
  '42.png',
  'noonoouri.png',
  'Workout Series.png',
  'Soufian.png',
  'Dayne S.png',
  'AON.png',
  'The Young Punx.png',
  'Jindalee.png',
  'Thomas Gold.png',
  'Marco Mori.png',
  'Pilo.png',
  'CHAOS LOL.png',
  'Betoko.png',
  'Einar.png',
  'Reda Rwena.png',
  'Adam Beyer.png',
  'Gutto Serta.png',
  'Xatar.png',
  'Sharam Jey.png',
  'Jamule.png',
  "Barry L'Affair.png",
  'Kardo.png',
  'Jink.png',
  'Eli & Fur.png',
  'Marvin Jam.png',
  'INNERVISIONS.png',
  'Pamela Reif.png',
  'Jamin.png',
  'Sil3a.png',
  'Nils Wülker & Arne Jansen.png',
  'Marc De Pulse.png',
  'Mufasa069.png',
  'L_Dre.png',
  'Max Schwugier.png',
  'York.png',
  'Jiggo.png',
  'Portrait XO.png',
  'Sarah Connor.png',
  'Diskotetris.png',
  'Dennis Korn.png',
  'Indie Elephant & Kole Audro.png',
  'Monet192.png',
  'Harris.png',
  'Prettyfacecapi.png',
  'Solee.png',
  'Ava Crown.png',
  'Lena.png',
  'Die Drei.png',
  'Haftbefehl.png',
  'GENETIKK.png',
  'Malik Harris.png',
  'Minaday.png',
  'Stefan Große Halbuer.png',
  'AK 33.png',
]

export const artists = artistList.map((artist, index) => ({
  id: index + 1,
  name: artist.split('.')[0],
  image: '/images/txt-play-images/artist_images/' + artist,
}))
