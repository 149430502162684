import Head from 'next/head'
import { TxtPlay } from '@twlvxtwlv/ui/src/components/pages/homepage/txt-play'
import type { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { _trackEventOnServerWithLogging } from '@twlvxtwlv/analytics/src/server'
import { _initDistinctId } from '@twlvxtwlv/utils/src/cookie'
import { HomepagePageComponent } from '@twlvxtwlv/ui/src/components/pages/homepage/shared/homepage-component'
import { useTranslation } from 'next-i18next'
import { OpenGraph } from '@twlvxtwlv/ui/src/components/shared/open-graph/open-graph'
import { absUrl, useOpenGraph } from '@twlvxtwlv/hooks'

import { useEffect, useState } from 'react'

const TxtPlayPage = () => {
  const { t } = useTranslation()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const ogProperties = useOpenGraph({
    locale: 'de',
    url: absUrl('/play'),
    title: t('common:meta-title-play'),
    image: {
      type: 'image/jpeg',
      url: '/images/og-image.jpg',
      alt: 'twelve x twelve logo',
    },
    description: t('common:meta-description-play'),
    type: 'website',
    card: 'summary',
  })

  return (
    <>
      {isMounted ? (
        <>
          <Head>
            <title>{t('meta-title-play')}</title>
            <meta name="description" content={t('meta-description-play') ?? undefined} />
            <OpenGraph properties={ogProperties} />
          </Head>
          <HomepagePageComponent>
            <TxtPlay />
          </HomepagePageComponent>
        </>
      ) : null}
    </>
  )
}

export const getServerSideProps = async ({
  req,
  res,
  locale = 'de',
}: GetServerSidePropsContext) => {
  const distinctId = _initDistinctId({
    req,
    res,
  })

  const userAgent = req.headers['user-agent'] as string
  const protocol = req.headers['x-forwarded-proto'] || 'http'
  const fullUrl = `${protocol}://${req.headers.host}${req.url}`
  const referrer = req.headers.referer || null
  const xForwardedFor = req.headers['x-forwarded-for'] as string | undefined
  const remoteAddress = req.socket.remoteAddress

  _trackEventOnServerWithLogging(
    { userId: distinctId },
    {
      eventName: 'homepage-txt-play-page-visited',
    },
    userAgent,
    xForwardedFor,
    remoteAddress,
    fullUrl,
    referrer
  )
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'navigation',
        'footer',
        'front-page',
        'user-management',
        'txt-play',
        'song-stocks',
      ])),
    },
  }
}
export default TxtPlayPage
