import { Stack } from '@chakra-ui/react'
import { TxtPlayHero } from './hero'
import { TxtPlayDescription } from './txt-play-description'
import { TxtPlayBuzzWords } from './txt-play-buzz-words'
import { CustomerEffort } from './customer-effort'
import { ProductDistribution } from './product-distribution'
import { TxtPlayEditorial } from './txt-play-editorials'
import { neueMachina } from '../../../../fonts'
import { _trackEventOnServerWithLogging } from '@twlvxtwlv/analytics/src/server'
import { _initDistinctId } from '@twlvxtwlv/utils/src/cookie'
import { TxtPlayNewsletter } from './txt-play-newsletter'

export const TxtPlay = () => {
  return (
    <Stack w="full" h="full" gap={0} fontFamily={neueMachina.style.fontFamily}>
      <TxtPlayHero />
      <TxtPlayBuzzWords />
      <TxtPlayDescription />
      <ProductDistribution />
      <CustomerEffort />
      <TxtPlayEditorial />
      <TxtPlayNewsletter />
    </Stack>
  )
}
