import cmsGraphQlClient from '../../client/graphql-client'
import type { GetCmsFrontPageQueryVariables } from '../../generated/graphql'
import { PublicationState } from '../../generated/graphql'
import gql from 'graphql-tag'

gql`
  fragment CmsFrontPage on Query {
    ...HottestDrop
    ...Editorials
    ...WhatWeDos
    ...NewArtist
  }
`

gql`
  query getCmsFrontPage(
    $editorialsPage: Int!
    $editorialsPageSize: Int!
    $whatWeDoPage: Int!
    $whatWeDoPageSize: Int!
    $locale: I18NLocaleCode
    $publicationState: PublicationState
  ) {
    ...CmsFrontPage
  }
`

export function getCmsFrontPageQuery(variables: GetCmsFrontPageQueryVariables) {
  if (variables.publicationState === null) {
    variables.publicationState = PublicationState.Live
  }

  return cmsGraphQlClient.getCmsFrontPage(variables)
}
