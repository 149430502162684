import { Text, Heading, Stack, Box, Image } from '@chakra-ui/react'
import './product-card-flip.css'
import { useState } from 'react'
import type { ProductSuite } from '@twlvxtwlv/types'
import { useTranslation } from 'next-i18next'
export interface DefaultProductCardFlipProps {
  product: ProductSuite
  getSectionBg: (product: ProductSuite) => void
}

export function ProductCardFlip({ product, getSectionBg }: DefaultProductCardFlipProps) {
  const { t } = useTranslation('txt-play')
  const [showDescription, setShowDescription] = useState(false)

  const { title1, title2, bgColor, bgImage, bgBlurImage, heading, arrowImage } = product
  const handleHover = () => {
    getSectionBg(product)
    setShowDescription(false)
  }

  return (
    <Stack className="box-container">
      <Stack className="box-item" p={2}>
        <Box className="flip-box" position="relative">
          <Box
            className="flip-box-front"
            alignItems="center"
            bgImage={bgImage}
            borderRadius={['20px', '20px', '40px', '40px']}
            onMouseEnter={handleHover}
            onMouseLeave={() => setShowDescription(false)}
          >
            <Stack as="span" color="txtWhite" top="5%" position="absolute" left="5%">
              <Image
                src={arrowImage}
                alt={arrowImage}
                width={6}
                height={6}
                style={{
                  objectFit: 'contain',
                  transform: 'rotate(180deg)',
                }}
              />
            </Stack>
            <Stack className="inner">
              <Heading className="card-heading" fontFamily="heading">
                {t(title1)}
                <br />
                {t(title2)}
              </Heading>
            </Stack>
          </Box>
          <Box
            className="flip-box-back"
            alignItems="center"
            bgImage={bgBlurImage}
            bgColor={bgColor}
            borderRadius={['20px', '20px', '40px', '40px']}
          >
            <Stack className="inner">
              {!showDescription && (
                <Text
                  className="card-description"
                  fontSize={{ base: 'md', sm: 'xl', md: '2xl', lg: '2xl', xl: '3xl' }}
                  color="txtWhite"
                  fontFamily="heading"
                >
                  {t(heading)}
                </Text>
              )}
            </Stack>
            <Box
              position="absolute"
              backgroundColor="black"
              top={0}
              left={0}
              w="100%"
              h="100%"
              borderRadius={['20px', '20px', '40px', '40px']}
              opacity="50%"
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}
