import { Stack, Heading, Image, Text, Box, Flex } from '@chakra-ui/react'
import type { EditorialEntity } from '@twlvxtwlv/cms'
import { toCmsMediaFileUrl } from '@twlvxtwlv/cms'

interface EditorialGridItemProps {
  editorial: EditorialEntity | null
}

export const EditorialsGridItem = ({ editorial }: EditorialGridItemProps) => {
  const thumbnail = editorial?.attributes?.thumbnail?.data?.attributes
  const category = editorial?.attributes?.category?.data?.attributes

  return (
    <Stack maxW={{ base: 'full', md: '764px' }} gap={[2]}>
      <Image
        src={toCmsMediaFileUrl(thumbnail?.url) ?? undefined}
        alt={thumbnail?.alternativeText ?? 'Editorials preview image'}
        w="full"
        fit="cover"
        h={{ base: 'md', lg: 'lg' }}
        borderRadius={12}
      />

      <Flex alignItems="center" gap={2} pt="0.5rem">
        <Text fontWeight="400" color={'txtDarkGray'} textTransform="uppercase">
          {category?.name}
        </Text>
        <Box
          bgColor={'txtDarkGray'}
          h={{ base: '4px', sm: '9px' }}
          w={{ base: '4px', sm: '9px' }}
          borderRadius="full"
        />
      </Flex>
      <Heading
        as="h2"
        fontFamily="heading"
        fontWeight="400"
        textTransform="uppercase"
        fontSize={{ base: 32, sm: 45, lg: 45 }}
        color="txtBlack"
      >
        {editorial?.attributes?.title}
      </Heading>
    </Stack>
  )
}
