import { Box, Text, AccordionItem, AccordionButton } from '@chakra-ui/react'
import './txt-play-artists-images.css'
import { motion } from 'framer-motion'

interface ScrollingArtistsItemProps {
  artist: any
  index: number
  numberOfArtists: number
  getHoveredArtist: (image: string) => void
}

export const TxtPlayArtistsItem = ({
  artist,
  index,
  numberOfArtists,
  getHoveredArtist,
}: ScrollingArtistsItemProps) => {
  const durationInSeconds = 9 // 9s -> 1 second per translateY keyframe from 0% -> 800%
  const repeatDelay = numberOfArtists - durationInSeconds

  return (
    <AccordionItem border={0}>
      <AccordionButton
        as={motion.span}
        color="txtWhite"
        fontFamily="body"
        whileHover={{ fontWeight: 900 }}
        onHoverStart={() => {
          getHoveredArtist(artist.image)
        }}
        onHoverEnd={() => {
          getHoveredArtist('')
        }}
        fontSize="lg"
        fontWeight="300"
        overflow="hidden"
        position="absolute"
        textOverflow="ellipsis"
        textTransform="uppercase"
        whiteSpace="nowrap"
        textAlign="right"
        mt="8px"
        borderBottom="1px solid "
        initial={{
          translateY: '-100%',
          opacity: 0,
        }}
        animate={{
          opacity: [0, 1, 1, 0],
          translateY: [
            '-100%',
            '0%',
            '100%',
            '200%',
            '300%',
            '400%',
            '500%',
            '600%',
            '700%',
            '800%',
          ],
          transition: {
            translateY: {
              delay: 1 * index, // 1 second delay per position
              duration: durationInSeconds,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatDelay,
              repeatType: 'loop',
              type: 'tween',
            },
            opacity: {
              delay: 1 * index,
              duration: durationInSeconds,
              ease: 'backInOut',
              repeat: Infinity,
              repeatDelay,
              repeatType: 'loop',
              times: [0, 0.15, 0.65, 0.8], // time when trigger transition keyframe for in/out control
              type: 'tween',
            },
          },
        }}
      >
        <Box flex="1" px={4}>
          <Text className="artist-name">
            <span className="arrow" style={{ display: 'none' }}>
              {' '}
              &#8627;
            </span>
            {artist.name}
          </Text>
        </Box>
      </AccordionButton>
    </AccordionItem>
  )
}
