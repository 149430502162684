import { Text, Heading } from '@chakra-ui/react'

export interface DefaultSectionHeadingProps {
  title: string
}

export function SectionHeading({ title }: DefaultSectionHeadingProps) {
  return (
    <Heading
      as="h1"
      fontSize={{ base: '4xl', sm: '3xl', md: '5xl', lg: '6xl' }}
      fontFamily="heading"
      fontWeight="500"
      display="flex"
      textTransform="uppercase"
      gap={2}
    >
      <Text as="span" fontSize={{ base: '3xl', sm: '3xl', md: '5xl' }}>
        &#8627;{' '}
      </Text>
      {title}
    </Heading>
  )
}
