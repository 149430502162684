import { Button, Stack, Text } from '@chakra-ui/react'
import type { EditorialEntity } from '@twlvxtwlv/cms'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { EditorialCollection } from '../../../shared/editorial-collection'

interface EditorialProps {
  editorials: EditorialEntity[]
}

export const EditorialsPreview = ({ editorials }: EditorialProps) => {
  const { t } = useTranslation(['front-page'])

  return (
    <Stack alignItems="center" gap={6} minW="full" px={{ base: 4, md: 'unset' }}>
      <EditorialCollection editorials={editorials} isFrontPage={true} />
      <Stack w="full" align="center">
        <Button variant="txtWhite" as={NextLink} href={`/en/editorials`}>
          <Text>{t('view-all')}</Text>
        </Button>
      </Stack>
    </Stack>
  )
}
