import { Box, Text, Show } from '@chakra-ui/react'
import { SectionHeading } from '../../../../shared/section-heading'
import { FeatureAccordion } from './feature-accordion'
import { LogoSectionScrollable } from '../../front-page/logo-section/logo-section-scrollable'
import { LogoSectionMobile } from '../../front-page/logo-section/logo-section-mobile'
import { useTranslation, Trans } from 'next-i18next'

export const CustomerEffort = () => {
  const { t } = useTranslation('txt-play')
  return (
    <Box minH={'100vh'} bgColor="txtLighterGray" py={4}>
      <Box px={[2, 2, 8, 12]} py={2}>
        <Text
          fontSize={['14px', '14px', '18px', '18px']}
          textTransform="uppercase"
          my={2}
          ml={[10, 10, 14, 14]}
        >
          {t('txt-play-why-title')}
        </Text>
        <SectionHeading title={t('txt-play-why-heading')} />

        <Box
          my={[6, 6, 8, 8]}
          width={['100%', '100%', '90%', '100%']}
          mx="auto"
          position="relative"
        >
          <FeatureAccordion />

          <Text my={4} py={4} fontSize={20}>
            <Trans
              i18nKey="myKey"
              defaults={t('txt-play-why-partner-text')}
              values={{ what: 'world' }}
              components={{
                b: <b />,
              }}
            />
          </Text>
        </Box>
      </Box>
      <Show above="md">
        <LogoSectionScrollable isHovered={false} />
      </Show>
      <Show below="md">
        <LogoSectionMobile />
      </Show>
    </Box>
  )
}
