import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { useForm } from 'react-hook-form'
import type { NewsletterOptInInput, SignUpErrorType } from '@twlvxtwlv/types'
import { z } from 'zod'

const schema = z.object({
  email: z.string().email().max(128),
  tocConsent: z.boolean(),
})

export function NewsletterOptInForm({
  onSubmit,
  variant,
  tocIsExternal = false,
  centerElements = false,
}: {
  error?: SignUpErrorType
  onSubmit: (values: NewsletterOptInInput) => void
  variant: 'default' | 'compact'
  tocIsExternal?: boolean
  centerElements?: boolean
}) {
  const { t } = useTranslation(['user-management', 'txt-play'])

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
  } = useForm<NewsletterOptInInput>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
  })

  const submitHandler = (values: NewsletterOptInInput) => {
    if (values.tocConsent) {
      onSubmit(values)
    } else {
      setError('tocConsent', { type: 'required' })
    }
  }

  const tocLink = tocIsExternal
    ? 'https://marketplace.twlvxtwlv.com/terms-of-service'
    : '/terms-of-service'

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      {variant === 'default' ? (
        <Stack spacing={8} maxW="500px">
          <Stack gap={2} fontFamily="body">
            <FormControl isInvalid={!!errors.email} isRequired>
              <Input
                border="none"
                borderBottom={'1px'}
                id="email"
                type="email"
                placeholder={t('email-placeholder') ?? undefined}
                autoComplete="email"
                {...register('email')}
                variant="outline"
              />
              {errors.email && (
                <FormErrorMessage>{t('missing-field', { fieldName: t('email') })}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errors.tocConsent}>
              <Checkbox id="toc-consent" {...register('tocConsent')}>
                {t('toc-consent')}
                <Link variant="txtBlack" target="_blank" as={NextLink} href={tocLink}>
                  {t('user-management:toc-url-title')}
                </Link>
              </Checkbox>
              {errors.tocConsent && (
                <FormErrorMessage>{t('missing-consent', { consent: t('toc') })}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
          <Button isLoading={isSubmitting} type="submit" fontFamily="heading">
            {t('user-management:receive-updates')}
          </Button>
        </Stack>
      ) : (
        <Flex
          w="full"
          direction={[
            'column',
            'column',
            'column',
            centerElements ? 'column' : 'row',
            centerElements ? 'column' : 'row',
          ]}
          gap="2rem"
          justifyContent={centerElements ? 'center' : 'inherit'}
        >
          <Stack
            gap={2}
            w={[
              'full',
              'full',
              'full',
              centerElements ? 'full' : '40%',
              centerElements ? 'full' : '40%',
            ]}
          >
            <FormControl isInvalid={!!errors.email} isRequired>
              <Input
                border="none"
                borderBottom={'1px'}
                id="email"
                type="email"
                placeholder={t('email-placeholder') ?? undefined}
                autoComplete="email"
                {...register('email')}
                variant="outline"
              />
              {errors.email && (
                <FormErrorMessage>{t('missing-field', { fieldName: t('email') })}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.tocConsent}>
              <Checkbox id="toc-consent" {...register('tocConsent')}>
                {t('toc-consent')}{' '}
                <Link variant="txtBlack" target="_blank" as={NextLink} href={tocLink}>
                  {t('toc-url-title')}
                </Link>
              </Checkbox>
              {errors.tocConsent && (
                <FormErrorMessage>{t('missing-consent', { consent: t('toc') })}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
          <Stack
            w={[
              'full',
              'full',
              'full',
              centerElements ? 'full' : '40%',
              centerElements ? 'full' : '40%',
            ]}
            alignItems={['end', 'end', 'end', 'center', 'center', 'center']}
          >
            <Button isLoading={isSubmitting} type="submit" variant="txtWhite">
              {t('sign-up-btn')}
            </Button>
          </Stack>
        </Flex>
      )}
    </form>
  )
}
