import { lazy } from 'react'
export const HomepagePageComponent = lazy(
  () =>
    new Promise(function (resolve) {
      setTimeout(() => {
        import('./homepage-page').then((result) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const response: any = result
          resolve(response)
        })
      }, 3000)
    })
)
