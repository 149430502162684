import { Box, Flex, Stack, Image } from '@chakra-ui/react'
import { logos } from './logos'

export const LogoSectionMobile = () => {
  return (
    <Box maxH="full" w="full" overflowY="hidden" overflowX="hidden" position="relative" pb={6}>
      <Flex direction="row" height="100%">
        <Stack
          display="grid"
          h="100%"
          w="100%"
          gridTemplateColumns="repeat(3, 1fr)"
          rowGap={12}
          columnGap={6}
          py={6}
          alignItems="center"
          justify="center"
        >
          {logos.map((logo, index) => (
            <Box
              key={index}
              w="100%"
              h="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginLeft: index > 2 ? '50% ' : '0 ' }}
            >
              <Image src={logo} alt={`Logo ${index + 1}`} />
            </Box>
          ))}
        </Stack>
      </Flex>
    </Box>
  )
}
