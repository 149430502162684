import { Box, Flex, Stack, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { logos } from './logos'

type LogoSectionProps = {
  isHovered: boolean
}

export const LogoSectionScrollable = ({ isHovered }: LogoSectionProps) => {
  const animDuration = 20
  return (
    <Box py={8} maxH="full" w="full" overflowY="hidden" overflowX="hidden" position="relative">
      <motion.div
        initial={{ translateX: '0%' }}
        animate={{ translateX: '-100vw' }} // Move by the viewport width
        transition={{ duration: animDuration, repeat: Infinity, ease: 'linear' }}
        style={{ width: '200vw', height: '100%' }} // Two sets of content
      >
        <Flex direction="row" height="100%">
          {/* Original Content */}
          <ScrollableContent logos={logos} isHovered={isHovered} />
          {/* Cloned Content */}
          <ScrollableContent logos={logos} isHovered={isHovered} />
        </Flex>
      </motion.div>
    </Box>
  )
}

const ScrollableContent = ({ logos, isHovered }: { logos: string[]; isHovered: boolean }) => {
  return (
    <Stack
      display="grid"
      h="100%"
      gridTemplateColumns="repeat(5, 1fr)"
      spacing={6}
      width="100vw"
      alignItems="center"
      justify="center"
    >
      {logos.map((logo, index) => (
        <Box
          key={index}
          w="100%"
          h="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            transition="filter 0.3s ease-in-out"
            filter={isHovered ? 'invert(1) grayscale(1) brightness(2)' : 'none'}
            src={logo}
            alt={`Logo ${index + 1}`}
          />
        </Box>
      ))}
    </Stack>
  )
}
