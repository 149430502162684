import { PublicationState } from '../generated/graphql'
import { getCurrentEnv } from '@twlvxtwlv/utils/get-current-env'

export function getPublicationStateFromEnv() {
  const env = getCurrentEnv()

  switch (env) {
    case 'development':
      return PublicationState.Preview

    case 'staging':
      return PublicationState.Preview

    case 'production':
      return PublicationState.Live

    default:
      return PublicationState.Live
  }
}
