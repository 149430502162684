import type { NewsletterOptInInput } from '@twlvxtwlv/types'
import { NewsletterOptInForm } from '../../../../shared/forms/newsletter-opt-in-form'

interface HomepageNewsletterSignupProps {
  centerElements?: boolean
  onSuccess: () => void
  onError?: () => void
  newsletterId: number
}

export const HomepageNewsletterSignupForm = ({
  centerElements = false,
  onError,
  onSuccess,
  newsletterId,
}: HomepageNewsletterSignupProps) => {
  const onNewsletterSubmit = async (newsletterOptInInput: NewsletterOptInInput) => {
    const { email } = newsletterOptInInput

    const newsletterResponse = await fetch('/api/sendinblue/create-contact', {
      method: 'PUT',
      body: JSON.stringify({ email, newsletterId }),
    })

    if (newsletterResponse.status === 201) {
      onSuccess()
    } else {
      onError?.()
    }
  }

  return (
    <NewsletterOptInForm
      onSubmit={onNewsletterSubmit}
      variant="compact"
      tocIsExternal
      centerElements={centerElements}
    />
  )
}
