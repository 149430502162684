import { Box, Stack } from '@chakra-ui/react'
import { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useTranslation } from 'next-i18next'

gsap.registerPlugin(ScrollTrigger)

type TxtPlayBuzzWordsProps = {
  speed: number
}

export const TxtPlayBuzzWordsText = ({ speed }: TxtPlayBuzzWordsProps) => {
  const { t } = useTranslation('txt-play')
  const container = useRef(null)
  const textContainer = useRef(null)
  const firstBox = useRef(null)
  const secondBox = useRef(null)
  const thirdBox = useRef(null)
  const fourthBox = useRef(null)
  const fifthBox = useRef(null)
  const sixthBox = useRef(null)

  useLayoutEffect(() => {
    if (container.current && textContainer.current) {
      const ctx = gsap.context(() => {
        gsap.to(textContainer.current, {
          ease: 'none',
          scrollTrigger: {
            trigger: container.current,
            start: 'top bottom',
            end: 'bottom -56px',
            onUpdate: (self) => {
              const progress = self.progress
              const xPosition = progress * speed
              const negativeXPosition = progress * -speed
              gsap.to(firstBox.current, { x: `${negativeXPosition}%` })
              gsap.to(secondBox.current, { x: `${xPosition}%` })
              gsap.to(thirdBox.current, { x: `${negativeXPosition}%` })
              gsap.to(fourthBox.current, { x: `${xPosition}%` })
              gsap.to(fifthBox.current, { x: `${negativeXPosition}%` })
              gsap.to(sixthBox.current, { x: `${xPosition}%` })
            },
          },
        })
      }, container.current)

      return () => {
        ctx.revert()
      }
    }
  }, [container, textContainer])

  return (
    <Box
      bgColor="txtLighterGray"
      py={4}
      px={[2, 2, 4, 8]}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="txtBlue"
      minH="60vh"
    >
      <Stack spacing={3}>
        <Box ref={container} w="full">
          <Box h="full" w="full" ref={textContainer} textAlign="center" position="relative">
            <Box w="100%" ref={firstBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-blue">{t('txt-play-buzz-word-2')}</span>
                  <span className="text-pill"> {t('txt-play-buzz-word-1')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-2')}</span>
                  <span className="text-pill"> {t('txt-play-buzz-word-1')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-2')}</span>
                </div>
              </Box>
            </Box>
            <Box w="100%" ref={secondBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-pill">{t('txt-play-buzz-word-3')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-4')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-3')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-4')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-3')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-4')}</span>
                </div>
              </Box>
            </Box>

            <Box w="100%" ref={thirdBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-pill">{t('txt-play-buzz-word-5')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-6')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-5')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-6')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-5')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-6')}</span>
                </div>
              </Box>
            </Box>
            <Box w="100%" ref={fourthBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-pill">{t('txt-play-buzz-word-7')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-8')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-7')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-8')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-7')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-8')}</span>
                </div>
              </Box>
            </Box>
            <Box w="100%" ref={fifthBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-pill">{t('txt-play-buzz-word-9')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-10')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-11')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-10')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-9')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-11')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-10')}</span>
                </div>
              </Box>
            </Box>
            <Box w="100%" ref={sixthBox}>
              <Box fontSize="5xl" textTransform="uppercase" whiteSpace="nowrap">
                <div className="txt-text">
                  <span className="text-blue">{t('txt-play-buzz-word-12')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-13')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-12')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-13')}</span>
                  <span className="text-blue">{t('txt-play-buzz-word-12')}</span>
                  <span className="text-pill">{t('txt-play-buzz-word-13')}</span>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
