import { useRef, useLayoutEffect } from 'react'
import { Heading } from '@chakra-ui/react'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

type TextAnimationProps = {
  word: string
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  scrollTrigger: ScrollTrigger | null
}

gsap.registerPlugin(SplitText)

export const TextAnimation = ({ word, position, scrollTrigger }: TextAnimationProps) => {
  const textRef = useRef(null)

  useLayoutEffect(() => {
    if (!scrollTrigger || !textRef.current) return

    const splitText = new SplitText(textRef.current, { type: 'words,chars' })
    const chars = splitText.chars

    chars.forEach((char, index) => {
      gsap.fromTo(
        char,
        { y: index % 2 === 0 ? -70 : 70 },
        {
          y: 0,
          ease: 'none',
          scrollTrigger: {
            trigger: '.hero-anim-container',
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: true,
          },
        }
      )
    })

    return () => splitText.revert()
  }, [scrollTrigger, textRef, position])

  return (
    <div>
      <Heading
        className={`text-animation ${position}`}
        fontSize={{ base: '3xl', sm: '4xl', md: '6xl', lg: '7xl' }}
        ref={textRef}
        textTransform="uppercase"
        as="h1"
        mixBlendMode="difference"
      >
        {word}
      </Heading>
    </div>
  )
}
