import { Button, Grid, Heading, Spinner, Stack } from '@chakra-ui/react'
import type { EditorialEntity } from '@twlvxtwlv/cms'
import { useTranslation } from 'next-i18next'
import { EditorialsPreviewCard } from '../../front/editorial/editorials-preview-card'

interface EditorialCollectionProps {
  editorials: EditorialEntity[] | null
  enableLoadMoreButton?: boolean
  isFrontPage?: boolean
  isLoadingMore?: boolean
  onLoadMore?: () => void
  isLoadingInitial?: boolean | undefined
}

export const EditorialCollection = ({
  editorials,
  enableLoadMoreButton,
  isFrontPage = false,
  isLoadingMore = false,
  onLoadMore,
  isLoadingInitial,
}: EditorialCollectionProps) => {
  const { t } = useTranslation(['common', 'editorials'])

  if (isLoadingInitial) {
    return (
      <Stack w="full" alignItems="center">
        <Spinner size="xl" />
      </Stack>
    )
  }

  return (
    <>
      {editorials && editorials.length > 0 ? (
        <Stack w="full" alignItems="center">
          <Grid
            templateColumns={
              isFrontPage
                ? {
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(2, 1fr)',
                    xl: 'repeat(3, 1fr)',
                  }
                : { base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }
            }
            gap={isFrontPage ? { base: 4, sm: 6 } : { base: 8, xl: 20 }}
          >
            {editorials.map((editorial, index) => (
              <EditorialsPreviewCard key={index} editorialContent={editorial} />
            ))}
          </Grid>
        </Stack>
      ) : (
        <Stack spacing={0} py={8}>
          <Heading as="h2" textAlign="center">
            {t('editorials:editorials-not-found')}
          </Heading>
        </Stack>
      )}

      {enableLoadMoreButton ? (
        <Stack w="full" align="center">
          <Button variant="txtWhite" onClick={onLoadMore} isLoading={isLoadingMore}>
            {t('load-more-items')}
          </Button>
        </Stack>
      ) : null}
    </>
  )
}
