import { useQuery } from '@tanstack/react-query'
import { Stack } from '@chakra-ui/react'
import { SectionHeading } from '../../../../shared/section-heading'
import type { EditorialEntity } from '@twlvxtwlv/cms'
import { EditorialsPreview } from '../../../homepage/front/editorial/editorials-preview'
import { getPublicationStateFromEnv } from '@twlvxtwlv/cms/src/utils'
import { getCmsFrontPageQuery } from '@twlvxtwlv/cms/src/pages'
import { useTranslation } from 'next-i18next'

export const TxtPlayEditorial = () => {
  const { t } = useTranslation('txt-play')
  const editorialsPage = 1
  const editorialsPageSize = 3

  const whatWeDoPage = 1
  const whatWeDoPageSize = 4
  const publicationState = getPublicationStateFromEnv()
  const { data: cmsFrontPage } = useQuery({
    queryKey: [
      'cmsFrontPage',
      {
        editorialsPage,
        editorialsPageSize,
        locale: 'de',
        publicationState,
        whatWeDoPage,
        whatWeDoPageSize,
      },
    ],
    queryFn: () =>
      getCmsFrontPageQuery({
        editorialsPage,
        editorialsPageSize,
        //locale,
        publicationState,
        whatWeDoPage,
        whatWeDoPageSize,
      }),
  })
  const editorialsContent = (cmsFrontPage?.editorials?.data as EditorialEntity[]) ?? null
  return (
    <Stack p={6}>
      <Stack mx={6} my={2}>
        <SectionHeading title={t('txt-play-editorial')} />
      </Stack>

      {editorialsContent ? <EditorialsPreview editorials={editorialsContent} /> : null}
    </Stack>
  )
}
