import { GridItem } from '@chakra-ui/react'
import type { EditorialEntity } from '@twlvxtwlv/cms'
import Link from 'next/link'
import { EditorialsGridItem } from '../editorials-grid-item'

interface EditorialsPreviewCardProps {
  editorialContent: EditorialEntity | undefined | null
}

export const EditorialsPreviewCard = ({ editorialContent }: EditorialsPreviewCardProps) => {
  const editorialsSlug = editorialContent?.attributes?.slug
  const editorialLink = editorialsSlug ? `/editorials/${editorialsSlug}` : `/editorials`
  const externalLink = editorialContent?.attributes?.externalEntry

  return (
    <>
      {editorialContent ? (
        <GridItem maxW={{ base: 'full', lg: '430px' }}>
          <Link
            href={externalLink ? externalLink : editorialLink}
            target={externalLink ? '_blank' : '_self'}
          >
            <EditorialsGridItem editorial={editorialContent as EditorialEntity} />
          </Link>
        </GridItem>
      ) : null}
    </>
  )
}
