import { Accordion, Image, Stack } from '@chakra-ui/react'
import './txt-play-artists-images.css'
import { useRef, useState } from 'react'
import { TxtPlayArtistsItem } from './txt-play-artists-item'
import { artists } from './artists'

export const TxtPlayArtistsImages = () => {
  const [bgImage, setBgImage] = useState<string>('')
  const refContainer = useRef(null)
  const getHoveredArtist = (image: string) => {
    setBgImage(image)
  }

  return (
    <Stack
      ref={refContainer}
      position="relative"
      width={{ base: '100%', md: '70%' }}
      h={320}
      margin="auto"
    >
      {bgImage && (
        <Image
          w={200}
          src={bgImage}
          h={200}
          alt={''}
          mx={[2, 16, 16, 8]}
          my="auto"
          borderRadius={12}
        />
      )}
      <Accordion w="full" defaultIndex={0} color="transparent" position="absolute">
        {artists.map((artist, index) => (
          <TxtPlayArtistsItem
            key={artist.id}
            artist={artist}
            numberOfArtists={artists.length}
            index={index}
            getHoveredArtist={(image) => getHoveredArtist(image)}
          />
        ))}
      </Accordion>
    </Stack>
  )
}
