import { Heading, Text, Stack, Grid, GridItem } from '@chakra-ui/react'
import { useState, Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import './customer-effort.css'
import { listContent } from './features'

export const FeatureAccordion = () => {
  const { t } = useTranslation('txt-play')
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSectionClick = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index)
  }

  return (
    <Fragment>
      <article className="accordion">
        <Grid
          w="full"
          templateColumns={{
            base: '1fr',
            sm: '90%',
            md: '90%',
            lg: '60%',
            xl: '50%',
          }}
          justifyContent="center"
          gap={[1, 2]}
          alignItems={'center'}
          my="auto"
        >
          {listContent.map((feature, index) => (
            <GridItem key={index} margin="auto" w="100%" mt={0}>
              <Stack
                as="section"
                className={activeIndex === index ? 'active' : ''}
                onClick={() => handleSectionClick(index)}
                cursor="pointer"
                mt={2}
                position="relative"
                borderRadius={[12, 12, 20, 20]}
              >
                <Heading
                  as="h2"
                  fontFamily="heading"
                  fontSize={{ base: '16px', sm: '20px', md: '24px', lg: '24px' }}
                >
                  {t(feature.title)}
                  {feature.subText ? (
                    <Text as="div" fontSize={{ base: 'sm', lg: 'md' }} p={0}>
                      {t(feature.subText)}
                    </Text>
                  ) : (
                    ''
                  )}
                </Heading>

                <Stack
                  className={
                    activeIndex === index ? 'active accordion-content' : 'accordion-content'
                  }
                >
                  <Heading
                    as="h3"
                    fontSize={{ base: '16px', sm: '20px', md: '24px', lg: '24px' }}
                    fontFamily="heading"
                    fontWeight={400}
                    textTransform="uppercase"
                  >
                    {t(feature.heading)}
                  </Heading>
                  <Text fontSize={{ base: 'sm', lg: 'md' }} fontFamily="body" textAlign="justify">
                    {t(feature.content)}
                  </Text>
                </Stack>
              </Stack>
            </GridItem>
          ))}
        </Grid>
      </article>
    </Fragment>
  )
}
