export const listContent = [
  {
    id: 1,
    title: 'txt-play-why-1',
    heading: 'txt-play-why-heading-1',
    content: 'txt-play-description-1',
  },

  {
    id: 2,
    title: 'txt-play-why-2',
    heading: 'txt-play-why-heading-2',
    content: 'txt-play-description-2',
  },
  {
    id: 3,
    title: 'txt-play-why-3',
    heading: 'txt-play-why-heading-3',
    content: 'txt-play-description-3',
  },
  {
    id: 4,
    title: 'txt-play-why-4',
    heading: 'txt-play-why-heading-4',
    content: 'txt-play-description-4',
  },
  {
    id: 5,
    title: 'txt-play-why-5',
    heading: 'txt-play-why-heading-5',
    subText: 'txt-play-why-coming-soon',
    content: 'txt-play-description-5',
  },

  {
    id: 6,
    title: 'txt-play-why-6',
    heading: 'txt-play-why-heading-6',
    subText: 'txt-play-why-coming-soon',
    content: 'txt-play-description-6',
  },

  {
    id: 7,
    title: 'txt-play-why-7',
    heading: 'txt-play-why-heading-7',
    content: 'txt-play-description-7',
  },

  {
    id: 8,
    title: 'txt-play-why-8',
    heading: 'txt-play-why-heading-8',
    content: 'txt-play-description-8',
  },
]
