import { Box, Grid, GridItem, Text, Image } from '@chakra-ui/react'
import { SectionHeading } from '../../../../shared/section-heading'
import { neueMachina } from '../../../../../fonts'
import { useTranslation } from 'next-i18next'
import { TxtPlayArtistsImages } from './txt-play-artists-images'

export const TxtPlayDescription = () => {
  const { t } = useTranslation('txt-play')
  return (
    <Box bgColor="txtLightGray">
      <Box
        borderRadius={12}
        bgColor="txtBlue"
        p={[8, 6, 6, 6]}
        h={['inherit', 'inherit', 'calc(100vh - 3.5rem)', 'calc(100vh - 3.5rem)']}
      >
        <Grid w="full">
          <Image
            objectFit="cover"
            src="/images/txt-play-images/txt-play-icon.png"
            alt="milestones"
            width={16}
            height={16}
          />
        </Grid>
        <Grid
          w="full"
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: '10% 40% 50%',
            xl: '10% 40% 50%',
          }}
          justifyContent="end"
          gap="1rem"
          fontFamily={neueMachina.style.fontFamily}
          color="txtWhite"
          className="txt-play-box"
        >
          <GridItem />
          <GridItem mx={6} my="auto">
            <Text
              fontSize={['14px', '14px', '18px', '18px']}
              textTransform="uppercase"
              my={2}
              ml={[10, 10, 14, 14]}
            >
              {t('txt-play-about-title')}
            </Text>

            <SectionHeading title={t('txt-play-about-heading')} />

            <Text textAlign={'justify'} mt={2} fontFamily="body">
              {t('txt-play-about-body')}
            </Text>
            <Grid
              w="full"
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(4, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
              gap=".5rem"
              fontSize={20}
              my={4}
            >
              <GridItem color="txtGreen">{t('txt-play-engage')}</GridItem>
              <GridItem color="#F4AAE3">{t('txt-play-identify')}</GridItem>
              <GridItem ml={[0, 0, 4, 4]} color="#9FBAF1">
                {t('txt-play-grow')}
              </GridItem>
              <GridItem color="txtYellow">{t('txt-play-monotize')}</GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <TxtPlayArtistsImages />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}
