import { useRef, useState, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Box, Show } from '@chakra-ui/react'
import { TextAnimation } from './text-animation'
import { HeroImage } from './hero-image'
import { HeroImageMobile } from './hero-image-mobile'
import { useTranslation } from 'next-i18next'
import './hero.css'

gsap.registerPlugin(ScrollTrigger)

export const TxtPlayHero = () => {
  const [scrollTrigger, setScrollTrigger] = useState<ScrollTrigger | null>(null)
  const { t } = useTranslation('txt-play')
  const heroRef = useRef(null)

  useLayoutEffect(() => {
    if (!heroRef.current) return
    const trigger = ScrollTrigger.create({
      trigger: heroRef?.current,
      start: 'top 56px',
      end: 'bottom bottom',
      scrub: true,
      pin: heroRef.current,
    })

    setScrollTrigger(trigger)

    return () => trigger.kill()
  }, [heroRef])

  return (
    <Box
      ref={heroRef}
      display="grid"
      gridTemplateColumns={['1fr']}
      position="relative"
      h="calc(220vh - 3.5rem)"
      className="hero-anim-container"
    >
      {scrollTrigger && (
        <>
          <Box width="100vw" h="calc(100vh - 3.5rem)">
            <Show above="md">
              <HeroImage scrollTrigger={scrollTrigger} />
            </Show>
            <Show below="md">
              <HeroImageMobile scrollTrigger={scrollTrigger} />
            </Show>
          </Box>
          <Box position="absolute" width="100vw" h="calc(100vh - 3.5rem)">
            <TextAnimation
              word={t('txt-play-engage')}
              position="top-left"
              scrollTrigger={scrollTrigger}
            />
            <TextAnimation
              word={t('txt-play-identify')}
              position="top-right"
              scrollTrigger={scrollTrigger}
            />
            <TextAnimation
              word={t('txt-play-grow')}
              position="bottom-left"
              scrollTrigger={scrollTrigger}
            />
            <TextAnimation
              word={t('txt-play-monotize')}
              position="bottom-right"
              scrollTrigger={scrollTrigger}
            />
          </Box>
        </>
      )}
    </Box>
  )
}
