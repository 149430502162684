export const productCard = [
  {
    id: 1,
    title1: 'DIGITAL',
    title2: 'VINYL',
    bgColor: 'txtPurple',
    bgImage: '/images/txt-play-images/DV_card.png',
    bgBlurImage: '/images/txt-play-images/DV_blur_card.png',
    arrowImage: '/images/txt-play-images/DV_Arrow.png',
    sectionBgImage: '/images/txt-play-images/DV_blur_bg.png',
    heading: 'txt-play-DV-heading',
    description: 'txt-play-DV-body',
  },
  {
    id: 2,
    title1: 'txt-play-PL-title1',
    title2: 'txt-play-PL-title2',
    bgColor: 'txtGreen',
    bgImage: '/images/txt-play-images/PL_card.png',
    bgBlurImage: '/images/txt-play-images/PL_blur_card.png',
    arrowImage: '/images/txt-play-images/PL_Arrow.png',
    sectionBgImage: '/images/txt-play-images/PL_blur_bg.png',
    heading: 'txt-play-PL-heading',
    description: 'txt-play-PL-body',
  },
  {
    id: 3,
    title1: 'txt-play-TS-title1',
    title2: 'txt-play-TS-title2',
    bgColor: '#F4AAE3',
    bgImage: '/images/txt-play-images/TS_card.png',
    bgBlurImage: '/images/txt-play-images/TS_blur_card.png',
    arrowImage: '/images/txt-play-images/TS_Arrow.png',
    sectionBgImage: '/images/txt-play-images/TS_blur_bg.png',
    heading: 'txt-play-TS-heading',
    description: 'txt-play-TS-body',
  },
  {
    id: 4,
    title1: 'txt-play-1F-title1',
    title2: 'txt-play-1F-title2',
    bgColor: '#9FBAF1',
    bgImage: '/images/txt-play-images/1F_card.png',
    bgBlurImage: '/images/txt-play-images/1F_blur_card.png',
    arrowImage: '/images/txt-play-images/1F_Arrow.png',
    sectionBgImage: '/images/txt-play-images/1F_blur_bg.png',
    heading: 'txt-play-1F-heading',
    description: 'txt-play-1F-body',
  },
]
