import { Box, Stack } from '@chakra-ui/react'
import { ChangeLanguage } from '../../../../shared/change-language'
import dynamic from 'next/dynamic'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })
type HeroImageProps = {
  scrollTrigger: ScrollTrigger | null
}
export const HeroImageMobile = ({ scrollTrigger }: HeroImageProps) => {
  const videoUrlMobile = '/video/TXT_Play_Hero_Mobile.mp4'
  const animationRef = useRef(null)

  const setupAnimation = (trigger: ScrollTrigger) => {
    return gsap.to(animationRef.current, {
      scrollTrigger: {
        ...trigger,
        start: 'top center',
        end: 'bottom bottom',
        scrub: true,
        pin: false,
      },
      width: '100%',
      height: '100%',
    })
  }
  useEffect(() => {
    let animation: gsap.core.Tween | null = null

    if (animationRef.current && scrollTrigger && ReactPlayer) {
      animation = setupAnimation(scrollTrigger)
    }

    return () => {
      if (animation) {
        animation.kill()
      }
    }
  }, [animationRef, scrollTrigger])

  return (
    <>
      <Stack
        px={{ base: '1rem', md: '8rem' }}
        position="relative"
        borderTop="1px"
        justifyContent="center"
        alignItems="center"
        h="calc(100vh - 3.5rem)"
        w="100vw"
      >
        <Box position="absolute" top="0" left="0" w="100%" h="100%" zIndex="auto">
          <ReactPlayer
            className="react-blur-player"
            height="100%"
            width="100%"
            playsinline
            muted
            playing
            loop
            controls={false}
            url={videoUrlMobile}
          />
        </Box>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="0"
          ref={animationRef}
          width="30%"
          height="30%"
        >
          <ReactPlayer
            className="react-player"
            height="100%"
            width="100%"
            playsinline
            muted
            playing
            loop
            controls={false}
            url={videoUrlMobile}
          />
        </Box>
        <Box position="absolute" bottom="2rem" left="2rem" zIndex={3}>
          <ChangeLanguage variant="dark" withLabel={false} size="xl" />
        </Box>
      </Stack>
    </>
  )
}
