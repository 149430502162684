import { Box, Heading, Stack, Show } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import './txt-play-buzz-words.css'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { TxtPlayBuzzWordsText } from './txt-play-buzz-words-text'

gsap.registerPlugin(ScrollTrigger)
export const TxtPlayBuzzWords = () => {
  const { t } = useTranslation('txt-play')

  return (
    <Box bgColor="txtLighterGray" display="flex" color="txtBlue" py={4} overflowX="hidden">
      <Stack spacing={3} w="100vw">
        <Heading
          w="full"
          as="h3"
          textAlign="center"
          fontSize={{ base: '4xl', sm: '4xl', md: '6xl', lg: '7xl', xl: '7xl' }}
          fontWeight={200}
          my={[2, 4, 4, 4]}
        >
          {t('txt-play-header')}
        </Heading>
        <Show above="md">
          <TxtPlayBuzzWordsText speed={15} />
        </Show>

        <Show below="md">
          <TxtPlayBuzzWordsText speed={50} />
        </Show>
      </Stack>
    </Box>
  )
}
